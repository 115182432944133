import './App.css';
import { CountDown, Cupo, Redirect, WhatsApp } from './controller';

import { GiDiamondRing, GiGlassCelebration } from "react-icons/gi";
import { PiDressLight } from "react-icons/pi";
import { FaAngleDown } from "react-icons/fa";
import { BsGift } from "react-icons/bs"; 
import { FaChurch } from "react-icons/fa";

function App() {
  const date = "Jul 27, 2024 03:30:00";

  return (
    <div className="App">
      <div className='photo'>
        <img alt="img" className='ring-img' src="https://www.davidanielawedding.online/anillo.JPG" />
        <div className="overlay">
          <h1 style={{marginTop:"300px", fontFamily:"cursive"}}>Nos casamos</h1>
          <h2 style={{fontFamily:"cursive"}}>David & Daniela</h2>
          <p style={{color:"white"}}>27 de Julio de 2024</p>
        </div>
        <div className='down'>
          <FaAngleDown/>
        </div>
      </div> 

      <div className='countdown'>  
        <div className='count-box'>
          {CountDown(date)}
          <div>
            <div className='count-container' id="count-days"></div>
            <p style={{fontWeight:"bold"}}>Días</p>
          </div>
          <div>
            <div style={{marginLeft:"10px"}} className='count-container' id="count-hours"></div>
            <p style={{fontWeight:"bold"}}>Horas</p>
          </div>
          <div>
            <div style={{marginLeft:"20px"}} className='count-container' id="count-minutes"></div>
            <p style={{fontWeight:"bold"}}>Minutos</p>
          </div>
          <div>
            <div style={{marginLeft:"25px"}} className='count-container' id="count-seconds"></div>
            <p style={{fontWeight:"bold"}}>Segundos</p>
          </div>
        </div>
      </div>
      <br />
      <div style={{marginTop:"-100px"}}>
        <GiDiamondRing className='icon' />
        <h2>¡Queremos invitarte!</h2>
        <p className='cursive'>Y que seas parte de este momento tan especial</p>
        <div className='count-box'>
          <div>
            <div className='cupos-container' id="cupos">{Cupo()}</div>
            <p style={{fontWeight:"bold"}} id="cp-text">{Cupo() > 1 && ("Cupos")}</p>
            <p style={{fontWeight:"bold"}} id="cp-text">{Cupo() === 1 && ("Cupo")}</p>
          </div>
        </div>  
        <p className='cursive' style={{fontSize:"20px"}}> 
          Por favor, ten en cuenta que este evento está reservado para adultos. 
          Deseamos que todos disfruten al máximo de este momento tan importante para nosotros.
        </p>
      </div>
      
      <div className='info'>
        <div>
          <FaChurch className='icon' />
          <h2>Ceremonia</h2>
          <p className='cursive'>Iglesia María Reina (Vergel), Ibagué Tol</p>
          <p className='date'>27 de Julio, 3:15pm</p>
          <button className='calltoaction' onClick={() => Redirect("https://maps.app.goo.gl/23UPnYm41ZUECrdH9")}>Ver ubicación</button>
        </div>
        <br />
        <div className='info-box'>
          <GiGlassCelebration className='icon' />
          <h2>Celebración</h2>
          <p className='cursive'>Quinta San Nicolás, Ibagué Tol</p> 
          <p className='date'>27 de Julio, 5:00pm </p>
          <button className='calltoaction' onClick={() => Redirect("https://maps.app.goo.gl/PJi55QTZRh6sqJbd8")}>Ver ubicación</button>
        </div>
        <br />
        <div className='info-box'>
          <PiDressLight className='icon' />
          <h2>Código de vestimenta</h2>
          <p className='cursive'>Formal</p> 
        </div>
        <br />
        <div className='info-box' style={{margin:"50px"}}>
          <BsGift className='icon' />
          <h2>Regalos</h2>
          <p className='cursive'>Lluvia de sobres.</p>
        </div>
        <br /> 
        <div className='info-box'>
          <h2>Asistencia</h2>
          <p className='cursive'>Por favor confirma tu asistencia antes del 15 de julio.</p>
          <br />
          <button className='calltoaction' onClick={() => WhatsApp()}>Confirmar</button>
        </div>
        <br />
        <div style={{minHeight:"300px"}}>
          <h2 style={{fontFamily:"cursive"}}>¡Te Esperamos!</h2>
        </div>
      </div> 
    </div>
  );
}

export default App;
