function obtenerParametrosURL() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const parametros = {};
    for (const [key, value] of params) {
        parametros[key] = value;
    }
    return parametros;
}

export function Cupo() {
    const parametros = obtenerParametrosURL(); 
    if ("cp" in parametros) {
        switch (parametros["cp"]) {
            case "dt2": 
                return 2
            case "cm3": 
                return 3
            default:
                return 1
        }
    }
    return 1
}

export function WhatsApp() {
    const phoneNumber = '573219636002'
    const message = 'Claro, ahi voy a estar!!'
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    Redirect(whatsappURL)
}


export function CountDown(startDate) {
    var countDownDate = new Date(startDate).getTime();
    var x = setInterval(function() { 
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000); 
        document.getElementById("count-days").innerHTML = days
        document.getElementById("count-hours").innerHTML = hours
        document.getElementById("count-minutes").innerHTML = minutes
        document.getElementById("count-seconds").innerHTML = seconds
        if (distance < 0) {
            clearInterval(x);
            document.getElementById("countdown").innerHTML = "¡YA ES EL MOMENTO!";
        }
    }, 1000)
}

export function Redirect(direccion) {
    window.location.replace(direccion)
}